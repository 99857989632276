<template>
  <v-container style="max-width: 1185px" class="">
    <v-card flat
      ><v-card-title class="text-md-h3 text-h4 mt-8"
        >Kontakt</v-card-title
      ></v-card
    >
    <v-container class="px-0">
      <v-row>
        <v-col sm="6" cols="11" order="last">
          <v-card
            flat
            v-for="(item, index) in KontaktListe"
            :key="index"
            class="my-8"
          >
            <v-card-title>{{ item.title }}</v-card-title>
            <v-card-text class="pb-0">{{ item.text }}</v-card-text>
            <v-card-text class="py-0">{{ item.zweitText }}</v-card-text>
          </v-card></v-col
        ><v-col sm="6" cols="11" order="sm-last" class="mx-auto">
          <v-img
            contain
            src="https://firebasestorage.googleapis.com/v0/b/mav-web-f1fa4.appspot.com/o/Bilder%2FKontakt.png?alt=media&token=ddd4fa2b-806d-4cda-a00b-95ba749efdc9"
            lazy-src="https://firebasestorage.googleapis.com/v0/b/mav-web-f1fa4.appspot.com/o/Bilder%2FKontakt.png?alt=media&token=ddd4fa2b-806d-4cda-a00b-95ba749efdc9"
          ></v-img>
        </v-col>
      </v-row>
    </v-container>
  </v-container>
</template>

<script>
export default {
  name: "KontaktView",
  metaInfo: {
    title: "Kontakt",
  },
  data: () => ({
    KontaktListe: [
      { title: "Inhaber:", text: "Stefan Mechsner und Horst Vilmar" },
      {
        title: "Öffnungszeiten: ",
        text: "Mo. bis Sa. 10:00 bis 20:00 Uhr",
        zweitText: "Die Rückgabe der Anhänger muss bis 20:00 Uhr erfolgen.",
      },
      { title: "E-Mail-Adresse:", text: "mav-bielefeld@gmx.de" },
      {
        title: "Telefon-Nr.:",
        text: "0176/56233264",
        zweitText: "Nur Anrufe, bitte keine WhatsApp / SMS",
      },
    ],
  }),
};
</script>

<style></style>
