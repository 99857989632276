<template>
  <v-dialog v-model="dialog" width="1000px">
    <template v-slot:activator="{ on, attrs }">
      <v-btn v-bind="attrs" v-on="on" icon>
        <font-awesome-icon
          color="rgb(48, 66, 83, 1)"
          size="2x"
          icon="fa-solid fa-circle-info"
      /></v-btn>
      <div v-bind="attrs" v-on="on" class="ml-2">Unsere Preise</div>
    </template>
    <v-card class="">
      <v-card-title>
        <span class="text-h5">Unsere Preise</span>
      </v-card-title>
      <v-card-text>
        <v-data-table
          dense
          disable-sort
          :headers="headers"
          :items="preise"
          hide-default-footer
          item-key="motorrad"
          class="custom_table_class"
        ></v-data-table>
        <p class="mt-2">
          Alle Anhänger sind mit einer Notfallbox ausgestattet.
        </p>
        <p>
          Anhänger für 1 Motorrad werden ab dem 8. Tag dann pro weiteren Tag nur
          8,00 € berechnet.<br />
          Anhänger für 2-3 Motorräder werden ab dem 8. Tag dann pro weiteren Tag
          nur 10,00 € berechnet.<br />
          Anhänger für 4 Motorräder werden ab dem 8. Tag dann pro weiteren Tag
          nur 13,00 € berechnet.<br />
          Anhänger für 5-6 Motorräder werden ab dem 8. Tag dann pro weiteren Tag
          nur 15,00 € berechnet.
        </p>
        <p class="font-weight-bold">
          Bei Abholung ist der Verleihpreis sowie die Kaution von 150,00 € in
          bar zu zahlen.
        </p>
        <p>
          Alle angegebenen Preise sind Endpreise zzgl. Liefer-/Versandkosten.<br />
          Aufgrund des Kleinunternehmerstatus gem. § 19 UStG erheben wir keine
          Umsatzsteuer und weisen diese daher auch nicht aus.
        </p>
        <p>
          <strong>Für Frühbucher:</strong><br />(Ausgenommen für 1 Motorrad)<br />
          Bei einer Buchung im Voraus von 3 Monaten oder mehr gibt es 11% Rabatt
          auf einen Buchungstermin von 11-14 Tagen.
        </p>
        <p>
          <strong>Bei Stornierungen:</strong><br />
          Bitte kontrolliert den Miet-Zeitraum, da wir alle Anschlussbuchungen
          danach ausrichten.<br />
          Wird die Buchung vor Antritt der Fahrt abgesagt, so sind brutto
          Mietkosten in Höhe:<br />
          2-5 Wochen vorher 50% zu zahlen,<br />
          7-13 Tage vorher 75% zu zahlen,<br />
          0-6 Tage vorher 100% des brutto Mietkosten zu zahlen.<br />
          Der Stornierungsbetrag ist innerhalb von 7 Tagen nach der Stornierung
          zu entrichten. <br />
        </p>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data: () => ({
    dialog: false,
    headers: [
      {
        text: "Mietpreise " + new Date().getFullYear(),
        align: "start",
        sortable: false,
        value: "motorrad",
        class: "white--text",
      },
      { text: "Für 1 Tag", value: "einenTag", class: "white--text" },
      { text: "Für 2 Tage", value: "zweiTage", class: "white--text" },
      { text: "Für 3 Tage", value: "dreiTage", class: "white--text" },
      { text: "Für 5 Tage", value: "fünfTage", class: "white--text" },
      { text: "Für 7 Tage", value: "siebenTage", class: "white--text" },
      { text: "Für 14 Tage", value: "vierzehnTage", class: "white--text" },
      { text: "Ab 15 Tage", value: "fünfzehnTage", class: "white--text" },
    ],
    preise: [
      {
        motorrad: "Anhänger für 1 Motorrad",
        einenTag: "25,00€",
        zweiTage: "40,00€",
        dreiTage: "55,00€",
        fünfTage: "85,00€",
        siebenTage: "105,00€",
        vierzehnTage: "165,00€",
        fünfzehnTage: "Preis auf Anfrage",
      },
      {
        motorrad: "Anhänger für 2-3 Motorräder",
        einenTag: "25,00€",
        zweiTage: "45,00€",
        dreiTage: "65,00€",
        fünfTage: "95,00€",
        siebenTage: "130,00€",
        vierzehnTage: "200,00€",
        fünfzehnTage: "Preis auf Anfrage",
      },
      {
        motorrad: "Anhänger für 4 Motorräder ",
        einenTag: "45,00€",
        zweiTage: "70,00€",
        dreiTage: "90,00€",
        fünfTage: "120,00€",
        siebenTage: "180,00€",
        vierzehnTage: "260,00€",
        fünfzehnTage: "Preis auf Anfrage",
      },
      {
        motorrad: "Anhänger für 5 Motorräder ",
        einenTag: "55,00€",
        zweiTage: "90,00€",
        dreiTage: "110,00€",
        fünfTage: "170,00€",
        siebenTage: "220,00€",
        vierzehnTage: "310,00€",
        fünfzehnTage: "Preis auf Anfrage",
      },
      {
        motorrad: "Anhänger für 6 Motorräder ",
        einenTag: "60,00€",
        zweiTage: "100,00€",
        dreiTage: "130,00€",
        fünfTage: "190,00€",
        siebenTage: "260,00€",
        vierzehnTage: "350,00€",
        fünfzehnTage: "Preis auf Anfrage",
      },
    ],
  }),
};
</script>

<style>
.custom_table_class thead th {
  background-color: #304253;
}
</style>
