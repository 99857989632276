<template>
  <div class="pt-lg-16 pt-8">
    <v-footer color="#304253" class="white--text pr-4 pl-4 mt-lg-16 mt-8">
      <!--Footer für lg Bildschirme und größer-->
      <div class="d-none d-lg-block mx-auto mt-16 mb-16" style="width: 1645px">
        <v-container fluid>
          <v-row class="align-end">
            <v-col class="pb-12 pl-0">
              <v-img
                alt="MAV Logo"
                class="shrink mr-5 justify-start d-xl-none d-lg-block"
                contain
                src="https://firebasestorage.googleapis.com/v0/b/mav-web-f1fa4.appspot.com/o/Icons%2Flogo.png?alt=media&token=6102d29d-9245-47bb-9cdb-f079a3bc2e7d"
                lazy-src="https://firebasestorage.googleapis.com/v0/b/mav-web-f1fa4.appspot.com/o/Icons%2Flogo.png?alt=media&token=6102d29d-9245-47bb-9cdb-f079a3bc2e7d"
                width="200"
              />
              <v-img
                alt="MAV Logo"
                class="shrink mr-5 justify-start d-none d-xl-block"
                contain
                src="https://firebasestorage.googleapis.com/v0/b/mav-web-f1fa4.appspot.com/o/Icons%2Flogo.png?alt=media&token=6102d29d-9245-47bb-9cdb-f079a3bc2e7d"
                lazy-src="https://firebasestorage.googleapis.com/v0/b/mav-web-f1fa4.appspot.com/o/Icons%2Flogo.png?alt=media&token=6102d29d-9245-47bb-9cdb-f079a3bc2e7d"
                width="260"
              /> </v-col
            ><v-spacer></v-spacer>
            <v-col class="" style="height: 300px">
              <v-list dark style="background-color: #304253" class="">
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title
                      >Inhaber: Stefan Mechsner und Horst
                      Vilmar</v-list-item-title
                    >
                  </v-list-item-content>
                </v-list-item>
                <v-list-item>
                  <v-list-item-icon
                    ><font-awesome-icon icon="fa-solid fa-door-open" size="2x"
                  /></v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title
                      >Öffnungszeiten: Mo. bis Sa. 10:00 bis 20:00 Uhr </v-list-item-title
                    ><v-list-item-subtitle
                      >Die Rückgabe der Anhänger muss bis 20:00 Uhr
                      erfolgen.</v-list-item-subtitle
                    >
                  </v-list-item-content>
                </v-list-item>
                <v-list-item
                  ><v-list-item-icon
                    ><a href="mailto: mav-bielefeld@gmx.de"
                      ><font-awesome-icon
                        icon="fa-solid fa-envelope"
                        color="white"
                        size="2x" /></a
                  ></v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title
                      >E-Mail-Adresse:
                      <a
                        class="text-decoration-none white--text"
                        href="mailto: mav-bielefeld@gmx.de"
                        >mav-bielefeld@gmx.de</a
                      ></v-list-item-title
                    ></v-list-item-content
                  >
                </v-list-item>
                <v-list-item
                  ><v-list-item-icon
                    ><font-awesome-icon icon="fa-solid fa-phone" size="2x"
                  /></v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>
                      Telefon-Nr. 0176/56233264</v-list-item-title
                    ><v-list-item-subtitle
                      >Nur Anrufe, bitte keine WhatsApp /
                      SMS</v-list-item-subtitle
                    >
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-col>
            <v-spacer></v-spacer>
            <v-col class="" style="height: 300px">
              <v-list dark style="background-color: #304253">
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>Standorte:</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>

                <v-list-item
                  ><v-list-item-icon
                    ><router-link :to="{ path: '/Standorte#Hillegossen' }"
                      ><font-awesome-icon
                        color="white"
                        icon="fa-solid fa-location-dot"
                        size="2x"
                    /></router-link>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title
                      ><router-link
                        :to="{ path: '/Standorte#Hillegossen' }"
                        class="text-decoration-none white--text"
                        >Hillegossen</router-link
                      ></v-list-item-title
                    >
                    <v-list-item-subtitle
                      >Stargarder Str. 28, 33699 Bielefeld</v-list-item-subtitle
                    ></v-list-item-content
                  >
                </v-list-item>
                <v-list-item
                  ><v-list-item-icon
                    ><router-link :to="{ path: '/Standorte#Brake' }"
                      ><font-awesome-icon
                        color="white"
                        icon="fa-solid fa-location-dot"
                        size="2x" /></router-link
                  ></v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>
                      <router-link
                        class="text-decoration-none white--text"
                        :to="{ path: '/Standorte#Brake' }"
                        >Brake</router-link
                      ></v-list-item-title
                    >
                    <v-list-item-subtitle
                      >Husumer Str. 112, 33729 Bielefeld</v-list-item-subtitle
                    ></v-list-item-content
                  >
                </v-list-item>
              </v-list> </v-col
            ><v-spacer></v-spacer>
            <v-col class="pr-0 pt-5" style="height: 300px">
              <v-list
                dark
                style="background-color: #304253"
                class="text-right pr-0"
              >
                <v-list-item class="pr-0">
                  <v-list-item-content class="pt-0">
                    <v-list-item-title
                      ><router-link
                        class="text-decoration-none white--text"
                        :to="'AGB'"
                        >AGB</router-link
                      ></v-list-item-title
                    >
                  </v-list-item-content>
                </v-list-item>
                <v-list-item class="pr-0">
                  <v-list-item-content class="pt-0">
                    <v-list-item-title
                      ><router-link
                        class="text-decoration-none white--text"
                        :to="'Mietbedingungen'"
                        >Mietbedingungen</router-link
                      ></v-list-item-title
                    >
                  </v-list-item-content>
                </v-list-item>
                <v-list-item class="pr-0">
                  <v-list-item-content class="pt-0">
                    <v-list-item-title
                      ><router-link
                        class="text-decoration-none white--text"
                        :to="'Datenschutz'"
                        >Datenschutzerklärung</router-link
                      ></v-list-item-title
                    >
                  </v-list-item-content> </v-list-item
                ><v-list-item class="pr-0">
                  <v-list-item-content class="pt-0">
                    <v-list-item-title
                      ><router-link
                        class="text-decoration-none white--text"
                        :to="'Kontakt'"
                        >Kontakt</router-link
                      ></v-list-item-title
                    >
                  </v-list-item-content> </v-list-item
                ><v-list-item class="pr-0">
                  <v-list-item-content class="pt-0">
                    <v-list-item-title
                      ><router-link
                        class="text-decoration-none white--text"
                        :to="'Impressum'"
                        >Impressum</router-link
                      ></v-list-item-title
                    >
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-col>
          </v-row></v-container
        >
      </div>

      <!--Footer für md Bildschirme -->
      <v-container class="d-lg-none d-sm-block d-none mb-16 mt-16">
        <v-img
          alt="MAV Logo"
          class="shrink mx-auto mb-16"
          contain
          src="https://firebasestorage.googleapis.com/v0/b/mav-web-f1fa4.appspot.com/o/Icons%2Flogo.png?alt=media&token=6102d29d-9245-47bb-9cdb-f079a3bc2e7d"
          lazy-src="https://firebasestorage.googleapis.com/v0/b/mav-web-f1fa4.appspot.com/o/Icons%2Flogo.png?alt=media&token=6102d29d-9245-47bb-9cdb-f079a3bc2e7d"
          width="265"
        />
        <v-list dark style="background-color: #304253" class="mx-auto">
          <v-list-group
            v-for="item in footerListeMD"
            :key="item.title"
            v-model="item.active"
            :prepend-icon="item.action"
            no-action
            class="py-0"
          >
            <template v-slot:activator>
              <v-list-item-content>
                <v-list-item-title v-text="item.menuTitle"></v-list-item-title>
              </v-list-item-content>
            </template>

            <v-list-item
              v-for="child in item.items"
              :key="child.title"
              class=""
            >
              <v-list-item-content>
                <v-list-item-title v-text="child.title"> </v-list-item-title>
                <v-list-item-subtitle
                  v-text="child.subtitle"
                ></v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list-group>
          <v-list-item>
            <v-list-item-content class="py-0">
              <v-list-item-title
                ><router-link
                  class="text-decoration-none white--text"
                  :to="'AGB'"
                  >Allgemeine Geschäftsbedingungen</router-link
                ></v-list-item-title
              >
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-content class="py-0">
              <v-list-item-title
                ><router-link
                  class="text-decoration-none white--text"
                  :to="'Mietbedingungen'"
                  >Mietbedingungen</router-link
                ></v-list-item-title
              >
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-content class="py-0">
              <v-list-item-title
                ><router-link
                  class="text-decoration-none white--text"
                  :to="'Datenschutz'"
                  >Datenschutzerklärung</router-link
                ></v-list-item-title
              >
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-content class="py-0">
              <v-list-item-title
                ><router-link
                  class="text-decoration-none white--text"
                  :to="'Impressum'"
                  >Impressum</router-link
                ></v-list-item-title
              >
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-container>
      <!--Footer für sm Bildschirme und kleiner-->
      <v-container class="d-sm-none mb-16 mt-16">
        <v-img
          alt="MAV Logo"
          class="shrink mx-auto mb-16"
          contain
          src="https://firebasestorage.googleapis.com/v0/b/mav-web-f1fa4.appspot.com/o/Icons%2Flogo.png?alt=media&token=6102d29d-9245-47bb-9cdb-f079a3bc2e7d"
          lazy-src="https://firebasestorage.googleapis.com/v0/b/mav-web-f1fa4.appspot.com/o/Icons%2Flogo.png?alt=media&token=6102d29d-9245-47bb-9cdb-f079a3bc2e7d"
          width="265"
        />
        <v-list dark style="background-color: #304253" class="mx-auto">
          <v-list-item>
            <v-list-item-content class="py-0">
              <v-list-item-title
                ><router-link
                  class="text-decoration-none white--text"
                  :to="'Kontakt'"
                  >Kontakt</router-link
                ></v-list-item-title
              >
            </v-list-item-content>
          </v-list-item>
          <v-list-group
            v-for="item in footerListeSM"
            :key="item.title"
            v-model="item.active"
            :prepend-icon="item.action"
            no-action
            class="py-0"
          >
            <template v-slot:activator>
              <v-list-item-content>
                <v-list-item-title v-text="item.menuTitle"></v-list-item-title>
              </v-list-item-content>
            </template>
            <v-list-item
              v-for="child in item.items"
              :key="child.title"
              class=""
            >
              <v-list-item-content>
                <v-list-item-title v-text="child.title"> </v-list-item-title>
                <v-list-item-subtitle
                  v-text="child.subtitle"
                ></v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list-group>
          <v-list-item>
            <v-list-item-content class="py-0">
              <v-list-item-title
                ><router-link
                  class="text-decoration-none white--text"
                  :to="'AGB'"
                  >Allgemeine Geschäftsbedingungen</router-link
                ></v-list-item-title
              >
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-content class="py-0">
              <v-list-item-title
                ><router-link
                  class="text-decoration-none white--text"
                  :to="'Mietbedingungen'"
                  >Mietbedingungen</router-link
                ></v-list-item-title
              >
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-content class="py-0">
              <v-list-item-title
                ><router-link
                  class="text-decoration-none white--text"
                  :to="'Datenschutz'"
                  >Datenschutzerklärung</router-link
                ></v-list-item-title
              >
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-content class="py-0">
              <v-list-item-title
                ><router-link
                  class="text-decoration-none white--text"
                  :to="'Impressum'"
                  >Impressum</router-link
                ></v-list-item-title
              >
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-container>
    </v-footer>
  </div>
</template>

<script>
export default {
  data: () => ({
    footerListeMD: [
      {
        items: [
          { title: "Inhaber: Stefan Mechsner und Horst Vilmar" },
          {
            title: "Öffnungszeiten: Mo. bis Sa. 10:00 bis 20:00 Uhr",
            subtitle: "Die Rückgabe der Anhänger muss bis 20:00 Uhr erfolgen.",
          },
          { title: "E-Mail-Adresse: mav-bielefeld@gmx.de" },
          {
            title: "Telefon-Nr.: 0176/56233264",
            subtitle: "Nur Anrufe, bitte keine WhatsApp / SMS",
          },
        ],
        menuTitle: "Kontakt",
      },
      {
        items: [
          {
            title: "Hillegossen",
            subtitle: "Stargarder Str. 28, 33699 Bielefeld",
          },
          {
            title: "Brake",
            subtitle: "Husumer Str. 112, 33729 Bielefeld",
          },
        ],
        menuTitle: "Standorte",
      },
    ],
    footerListeSM: [
      {
        items: [
          {
            title: "Hillegossen",
            subtitle: "Stargarder Str. 28, 33699 Bielefeld",
          },
          {
            title: "Brake",
            subtitle: "Husumer Str. 112, 33729 Bielefeld",
          },
        ],
        menuTitle: "Standorte",
      },
    ],
  }),
  methods: {},
};
</script>

<style></style>
