<template>
  <v-container style="max-width: 1185px" class="">
    <v-card flat
      ><v-card-title class="text-md-h3 text-h4 mt-8"
        >Allgemeine Geschäftsbedingungen</v-card-title
      ></v-card
    >
    <v-container class="px-0">
      <v-card flat class="my-8">
        <v-card-title class="font-weight-bold"
          >Gliederung unserer AGB:</v-card-title
        >
        <v-card-text
          ><v-list-item>
            <v-list-item-content>
              <v-list-item-title>I. Allgemeines</v-list-item-title>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>1. Versicherung</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>2. Haftung</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>3. Reparatur</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-content> </v-list-item
          ><v-list-item>
            <v-list-item-content>
              <v-list-item-title>II. Pflichten des Mieters</v-list-item-title>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>1. Anzeigepflicht</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title
                    >2. Mietdauer und Rückgabe</v-list-item-title
                  >
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>3. Auslandsfahrten</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title
                    >4. Kaution und Zahlungsbedingungen</v-list-item-title
                  >
                </v-list-item-content>
              </v-list-item></v-list-item-content
            ></v-list-item
          >
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title
                >III. Datenschutzklausel</v-list-item-title
              ></v-list-item-content
            ></v-list-item
          >
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title
                >IV. Gerichtsstand</v-list-item-title
              ></v-list-item-content
            ></v-list-item
          ></v-card-text
        >
      </v-card>
    </v-container>
    <v-container class="px-0 text-justify">
      <v-card flat v-for="(item, index) in AGBListe" :key="index" class="my-8">
        <v-card-title class="font-weight-bold text-h4 text-justify">{{
          item.hauptTitle
        }}</v-card-title>
        <v-card-title class="font-weight-bold">{{ item.title }}</v-card-title>
        <v-card-text>{{ item.text }}</v-card-text>
      </v-card>
    </v-container>
  </v-container>
</template>

<script>
export default {
  name: "AGBView",
  metaInfo: {
    title: "AGB",
  },
  data: () => ({
    AGBListe: [
      {
        hauptTitle: "I. Allgemeines",
        text: "Grundlage dieses Mietvertrages zwischen Vermieter MAV und dem Mieter sind ausschließlich die aufgeführten Vertragsbedingungen. Mündliche Abreden haben keine Gültigkeit. Durch seine Unterschrift erkennt der Mieter an, dass er den Anhänger in einem technisch einwandfreien Zustand übernommen hat.",
      },
      {
        title: "1. Versicherung",
        text: "Der Anhänger ist gemäß den jeweils geltenden allgemeinen Bedingungen für die Kraftversicherung wie folgt versichert: Haftpflichtversicherung: 100 Mio. Euro pauschal, bei Personenschäden jedoch maximal 15 Mio. je geschädigte Person Teilkaskoversicherung: Diese deckt Schäden im Falle von Brand, Explosion, Entwendung und Elementarereignissen, sowie Glas- und Wildschäden ab. Selbstbeteiligung 150,00 EUR.",
      },
      {
        title: "2. Haftung",
        text: "Der Mieter haftet grundsätzlich dem Vermieter bei Eintritt von Schäden am Anhänger in voller Höhe für den dem Vermieter entstandenen unmittelbaren und mittelbaren Schaden. Der Mieter haftet für die Reparaturkosten im Schadensfall. Darüber hinaus haftet er in voller Höhe für Abschleppkosten, Sachverständigengebühren, Wertminderung und Mietausfall. Der Mieter ist für die Einhaltung der Anhängelast seines Fahrzeuges allein verantwortlich, sowie für Verkehrsverstöße aller Art. Haftung des Vermieters ist ausgeschlossen. Auch für Reifenschäden jeglicher Art, sowie für die Dichtheit des Aufbaus und der Plane wird keine Haftung vom Vermieter übernommen. Folgeschäden am Fahrzeug oder dem Ladegut werden vom Vermieter nicht übernommen und somit ausdrücklich ausgeschlossen. Der Mieter ist nicht berechtigt, mündlich oder schriftlich ein Schuldanerkenntnis zu erteilen oder durch sonstige Äußerungen, Zugeständnisse oder gar Zahlungen einer Regulierung des Schadensfalles durch die für den Mietanhänger abgeschlossene Haftpflichtversicherung vorzugreifen.",
      },
      {
        title: "3. Reparatur",
        text: "Bei Auftreten von Schäden, dürfen diese nur nach Rücksprache und Maßgabe des Vermieters durchgeführt werden. Anderenfalls trägt der Mieter die hierfür angefallenen Kosten und haftet für jeden Schaden, den der Vermieter hierdurch erleidet.",
      },
      {
        hauptTitle: "II. Pflichten des Mieters",
        text: "Der Mieter ist verpflichtet, den Anhänger sorgsam zu behandeln und alle für die Benutzung maßgeblichen Vorschriften und technischen Regeln zu beachten. Der Mieter ist für sich oder dessen Fahrer für die Fahrtüchtigkeit und des Vorhandenseins einer gültigen Fahrerlaubnis selbst verantwortlich. Die Beladung des Anhängers ist nur im gesetzlichen Rahmen zulässig. Der Anhänger ist vom Mieter sorgfältig gegen Diebstahl zu sichern, verstößt der Mieter gegen diese Bedingung, so muss er vollen Schadenersatz bis zur Höhe des Wiederbeschaffungswertes zuzüglich Mietausfalls leisten. Als grob fahrlässig und zum Ausschluss jeder Versicherungsleistung sowie zur Vollhaftung des Mieters/Fahrers führt insbesondere Nichtbeachtung von: Durchfahrtshöhen- u. Breiten, Gewichten/Gewichtsverteilungen, Anhänge- u. Stützlasten, Geschwindigkeiten, Ladungssicherung. Der Anhänger darf nicht an Dritte weiter verliehen werden. Wichtig: Da der Anhänger über eine Rückfahrautomatik verfügt muss folgendes beachtet werden: Wird nach dem Rückwärtsfahren die Feststellbremse angezogen, Hält sie nicht, da sie noch gelöst ist. Erst die Auflaufbremse auseinanderziehen und dann die Feststellbremse anziehen.",
      },
      {
        title: "1. Anzeigepflicht",
        text: "Die Polizei ist in jedem Fall zur Ermittlung der Unfalltatsachen hinzuzuziehen. Der Vermieter ist sofort zu verständigen und spätestens bei Rückgabe des Anhängers über alle Einzelheiten schriftlich unter Vorlage einer Skizze zu unterrichten, selbst dann, wenn Sie in der Zwischenzeit behoben sein sollten. Der Unfallbericht muss insbesondere Namen und Anschriften der beteiligten Personen und etwaiger Zeugen, sowie die amtlichen Kennzeichen der beteiligten Fahrzeuge enthalten. Gegnerische Ansprüche dürfen nicht anerkannt werden. Brand- oder Entwendungsschäden, sowie Wildschäden sind vom Mieter dem Vermieter, sowie der zuständigen Polizeibehörde unverzüglich anzuzeigen.",
      },
      {
        title: "2. Mietdauer und Rückgabe",
        text: "Wird der Rückgabetermin nicht eingehalten, ist der Vermieter frühzeitig telefonisch zu benachrichtigen, außerdem ist der Mieter zur Zahlung einer zusätzlichen Tagesmiete verpflichtet. Nach zwei Tagen ohne Benachrichtigung erfolgt Anzeige wegen Unterschlagung. Für hieraus entstehende Schäden dem Vermieter gegenüber, hat der Mieter vollen Schadenersatz zu leisten. Der Mieter ist verpflichtet, den Anhänger zum vereinbarten Termin dem Vermieter an dessen Adresse zurückzugeben. Sollte der Anhänger ohne eine telefonische Benachrichtigung nicht zum Rückgabedatum zurückgebracht werden, ist für jeden weiteren Kalendertag ein Betrag bis zu 50,- € fällig.",
      },
      {
        title: "3. Auslandsfahrten",
        text: "Fahrten außerhalb des Bundesgebietes müssen immer, ohne Ausnahme, vor Antritt der Fahrt bzw. bei Abschluss des Mietvertrages dem Vermieter gemeldet bzw. muss sich die Erlaubnis vom Vermieter eingeholt werden. Wird diese erteilt, so beschränkt sie sich Fahrten und Aufenthalte in Europa*. * zugelassene Länderkennzeichnung des Versicherungsunternehmen.",
      },
      {
        title: "4. Kaution und Zahlungsbedingungen",
        text: "Der Mietpreis und die Kaution ist immer im Voraus und in Bar bei Vertragsabschluss zu entrichten.",
      },
      {
        hauptTitle: "III. Datenschutzklausel",
        text: "Der Mieter ist damit einverstanden, dass seine persönlichen Daten vom Vermieter gespeichert und dass diese über den zentralen Warnring an Dritte weitergegeben werden, wenn a. die bei der Anmietung gemachten Angaben unrichtig sind; b. das gemietete Fahrzeug nicht innerhalb 24 Std. der gegebenenfalls verlängerten Mietzeit zurückgegeben wird; c. der Mieter gegen einen oder mehrere Punkte des Vertrages verstößt; d. bei Verkehrsverstöße, im In- und Ausland, werden die Daten an die zugehörige Dienststelle weiter geleitet.",
      },
      {
        hauptTitle: "IV. Gerichtsstand",
        text: "Für alle Streitigkeiten aus und im Zusammenhang mit diesem Mietvertrag wird immer der Sitz des Vermieters als Gerichtsstand vereinbart. Wenn als Gerichtsstand der des Vermieters gültig ist, kann dieser auch einen anderen seiner Wahl bevorzugen, z.B. den Ort des Vertragsabschlusses, den Sitz seines Erfüllungsgehilfen, den Unfall- oder Tatort, etc.",
      },
    ],
  }),
};
</script>

<style></style>
